<template>
  <div v-if="showBindPhone">
    <div>
      <div>
        <h2 class="fs-24 marginB26">{{$t('bindTitle')}}</h2>
        <p
          class="gray"
          style="margin-bottom: 60px;">{{$t('bindTips')}}</p>
      </div>
      <el-form
        :model="codeForm"
        :rules="rules"
        ref="codeForm">
        <el-form-item prop="phone">
          <span class="phone-icon">+86</span>
          <el-input
            class="phone-input"
            @keyup.enter.native="bindChange"
            v-model="codeForm.phone"
            :placeholder="$t('bindPlaceholder')"></el-input>
        </el-form-item>
        <el-form-item prop="verifyCode">
          <el-input
            @keyup.enter.native="bindChange"
            class="code-input"
            v-model="codeForm.verifyCode"
            :placeholder="$t('verifyCodeTip')"></el-input>
          <span
            class='cursor code-icon'
            @click='sendCode'>{{canSend ? $t('sendCodePhone') : timeNum + 's'}}</span>
        </el-form-item>
      </el-form>
    </div>
    <el-button
      class="sign-submit-btn phone-btn"
      :loading="isLoading"
      @click="bindChange">{{$t('bindPhone')}}</el-button>
    <div
      class="back-btn"
      @click="back">
      <span class="border">{{$t('back')}}</span>
    </div>
  </div>
</template>

<script>
import { getUrlParam } from '@/assets/js/utils'
import { mapState } from 'vuex'
export default {
  name: 'WxLogin',
  data () {
    return {
      codeForm: {
        phone: '',
        verifyCode: ''
      },
      code_token: '',
      showBindPhone: false,
      canSend: true,
      timeNum: 59,
      domainName: '',
      isLoading: false
    }
  },
  computed: {
    rules () {
      return {
        phone: [
          { required: true, message: this.$t('bindPlaceholder'), trigger: 'blur' },
          { pattern: /^1[0-9]{10}$/, message: this.$t('enterTelTip'), trigger: 'blur' }
        ],
        verifyCode: [
          { required: true, message: this.$t('verifyCodeTip'), trigger: 'blur' }
        ]
      }
    },
    ...mapState({
      httpsFlag: 'httpsFlag',
      wxUrl: 'wxUrl',
      wxServiceName: 'wxServiceName',
      showPopup: 'showPopup'
    })
  },
  created () {
    this.getInfo()
    if (getUrlParam('code')) {
      this.codeGetToken()
    }
  },
  methods: {
    getInfo () {
      // 获取domain
      const localUrl = window.location.hostname
      const str1 = localUrl.split('.')[1]
      const str2 = localUrl.split('.')[2]
      this.domainName = `.${str1}.${str2}`
    },
    codeGetToken () {
      const params = {
        wechatCode: getUrlParam('code')
      }
      if (this.wxUrl && this.wxServiceName) {
        params.serverName = this.wxServiceName
        params.redirectUrl = this.wxUrl
      }
      this.$axios.post('/userGroup/user-group/login/third/wechat', params).then(res => {
        if (res.data.errorCode == '0x0') {
          this.loginSuccess(res.data.result)
        } else if (res.data.errorCode == '80944303') {
          this.code_token = res.data.result
          this.showBindPhone = true
        } else if (res.data.errorCode == '80944301') {
          this.$message.error(this.$t('signFail'))
          this.back()
        } else {
          this.$message.error(res.data.errorInfo)
          this.back()
        }
      }).catch(() => {
      })
    },
    bindChange () {
      this.$refs.codeForm.validate((valid) => {
        if (!valid) {
          return
        }
        const params = {
          platform: 'wechat',
          verifyCode: this.codeForm.verifyCode,
          phone: this.codeForm.phone,
          token: this.code_token
        }
        if (this.wxServiceName && this.wxUrl) {
          params.serverName = this.wxServiceName
          params.redirectUrl = this.wxUrl
        }
        this.isLoading = true
        this.$axios.post('/userGroup/user-group/login/third/verifyMsgCode', params).then(res => {
          if (res.data.errorCode == '0x0') {
            this.loginSuccess(res.data.result)
          } else if (res.data.errorCode == '80944301') {
            this.$message.error(this.$t('signFail'))
          } else if (res.data.errorCode == '80943304') {
            this.$message.error(this.$t('error80943202'))
          } else if (res.data.errorCode == '80943305') {
            this.$message.error(this.$t('error80943701'))
          } else {
            this.$message.error(res.data.errorInfo)
          }
          this.isLoading = false
        }).catch(() => {
          this.isLoading = false
        })
      })
    },
    sendCode () {
      if (!this.canSend) return
      const phone = this.codeForm.phone.replace(/\s*/g, '')
      if (!this.codeForm.phone || !(/^1[0-9]{10}$/.test(phone))) {
        return this.$message.error(this.$t('enterTelTip'))
      }
      const params = {
        phone: this.codeForm.phone,
        token: this.code_token
      }
      this.$axios.post('/userGroup/user-group/login/sendMsgCode', params).then(res => {
        if (res.data.errorCode == '0x0') {
          this.$message.success(this.$t('sendSuccess'))
          this.sendCodeChange()
        } else if (res.data.errorCode == '80945301') {
          this.$message.error(this.$t('error80945301'))
        } else if (res.data.errorCode == '80943201') {
          this.$message.error(this.$t('error80943201'))
        } else if (res.data.errorCode == '80945302') {
          this.$message.error(this.$t('error80945302'))
        } else {
          this.$message.error(res.data.errorInfo)
        }
      }).catch(() => {
      })
    },
    sendCodeChange () {
      this.canSend = false
      this.timeNum = 59
      this.timer = setInterval(() => {
        this.timeNum--
        if (this.timeNum == 0) {
          clearInterval(this.timer)
          this.canSend = true
        }
      }, 1000)
    },
    // 清除tagkey
    clearTagKey () {
      const exp = new Date()
      exp.setTime(exp.getTime() - 1)
      this.tagKeys.forEach((item) => {
        window.document.cookie = `${item}=;path=/;domain=${this.domainName};expires=${exp.toGMTString()}`
      })
    },
    loginSuccess (data) {
      localStorage.setItem('email', this.codeForm.phone)
      localStorage.setItem('firstName', data.firstName)
      localStorage.setItem('userName', data.userName)
      localStorage.setItem('session', data.session)
      localStorage.setItem('userservice', data.userservice)
      window.userservice = data.userservice
      // media hub弹窗内登录成功之后，先将tagkey清除，再存储
      // if (this.showPopup) {
      //   this.clearTagKey()
      // }
      // 登录成功之后，先将tagkey清除，再存储
      this.clearTagKey()
      if (this.domainName) {
        // window.document.cookie = `SID=${data.session};path=/;domain=${this.domainName};${this.httpsFlag ? 'secure' : ''}`
        if (data.tagList && data.tagList.length > 0 && this.$store.state.saveTagCookie) {
          data.tagList.forEach(itm => {
            window.document.cookie = `${itm.tagKey}=${itm.tagValue};path=/;domain=${this.domainName};${this.httpsFlag ? 'secure' : ''}`
          })
        }
      }
      // 此时设置sid有效
      this.$store.commit('changesValidSid', true)
      // web跳转
      if (data.loginWelcome == '1') {
        this.$router.push('/welcome')
      } else {
        window.top.location.href = data.redirectUrl
      }
    },
    back () {
      this.$router.push('/qrcode')
    }
  }
}
</script>

<style scoped lang="less">
.input-phone {
  width: 75%;
}
.phone-btn {
  margin-top: 34px;
  margin-bottom: 36px;
}
.back-btn {
  text-align: center;
  color: #47A325;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
}
.phone-icon {
  position: absolute;
  z-index: 2;
  left: 20px;
  top: 4px;
  font-size: 13px;
}
.phone-input /deep/.el-input__inner {
  padding: 15px 54px;
}
.code-icon {
  position: absolute;
  right: 12px;
  top: 4px;
  font-size: 13px;
  color: #47A325;
  font-weight: 500;
}
</style>
